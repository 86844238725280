// Dependencies
import { useState, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';

// Chakra
import {
  Box,
  Button,
  Flex,
  Heading,
  VStack,
  HStack,
  useBreakpointValue
} from '@chakra-ui/react';
import { ewavesConfig } from '../config/theme-config';

// Providers
import { useChart } from '../providers/chart';
import { useView } from '../providers/view';

// Hooks
import { useWindowSize, useDimensions } from '../hooks';

// Components
import {
  Breadcrumbs,
  Chart,
  ChartFooter,
  Fullscreen,
  DownloadBlobButton,
} from '../components';


export default function Single() {
  const {setNavFromSearch} = useView();
  const {activeMarket, zoom, chartBlob, setChartInView} = useChart();
  const navigate = useNavigate();
  const breadcrumbs = useReactRouterBreadcrumbs();
  const [width, height] = useWindowSize();

  const chartRef = useRef(null);

  const buttonStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    h: 10,
    px: 4,
    py: 4,
    bgColor: 'primary.200',
    color: 'blackAlpha.900',
    borderRadius: 4,
    fontWeight: '500',
    lineHeight: '1',
    '&:hover': {
      bgColor: 'gray.200',
      textDecoration: 'none'
    }
  }

  // create filename in this format: Symbol-YYMMDD-Zoom.svg
  const filename = useMemo(() => {
    if(activeMarket && zoom) {
      return `${activeMarket?.json?.Symbol}-${(new Date()).toISOString().slice(0,10).replace(/-/g,"")}-z${zoom}`;
    }
  }, [activeMarket, zoom]);

  const chartHeightFS = useMemo(() => {
    const targetAspectRatio = 16 / 9;
    const currentAspectRatio = width / height;

    if(currentAspectRatio > targetAspectRatio) {

      return height - 10;
    } else {
      return null;
    }
  }, [width, height]);

  // Necessary adjustments for sizing. Similar to <Thumbnail /> and <Full /> views types.
  const [totalHeight, setTotalHeight] = useState(0);
  const [maxChartHeight, setMaxChartHeight] = useState(0);
  const [sRef, size] = useDimensions();
  const [windowWidth, windowHeight] = useWindowSize();

  const headerHeight = useBreakpointValue({ base: ewavesConfig.headerHeightSmall, desktop: ewavesConfig.headerHeightLarge }, { ssr: false });
  const aspectRatio = 16 / 9;

  useLayoutEffect(() => {
    const screenAspect = windowWidth / windowHeight;
    const chartHeight = size.width / aspectRatio;

    let calcHeight;

    if(screenAspect > 1.85) {
      calcHeight = ((windowHeight - (headerHeight)) - 16) + ewavesConfig.chartFooterMinHeight + ewavesConfig.chartHeaderMinHeight;
    } else {
      calcHeight = chartHeight + ewavesConfig.chartFooterMinHeight + ewavesConfig.chartHeaderMinHeight;
    }
      
    setTotalHeight(calcHeight);
    setChartInView(true); // Necessary for single view to show chart, since <Views /> otherwise sets this value and Single isn't rendered inside Views.
  }, [size, aspectRatio, setChartInView, windowWidth, windowHeight, headerHeight]);

  useLayoutEffect(() => {
    const screenAspect = windowWidth / windowHeight;

    if(screenAspect > 1.85) {
      setMaxChartHeight((windowHeight - (headerHeight)) - 16);
    } else {
      setMaxChartHeight('100%');
    }
  }, [totalHeight, windowWidth, windowHeight, headerHeight]);

  // When single view loads set this flag to false
  useEffect(() => {
    setNavFromSearch(false);
  }, [setNavFromSearch]);

  return (
    <>
      {activeMarket &&
        <Flex className={'single-view'} flexDirection={'column'} pb={5} width={'100%'}>
          <Box>
            <Box width={'100%'} textAlign={'left'}>
              <Button size='sm' color={'green.500'} variant={'link'} aria-label='Go back' bgColor={'transparent'} leftIcon={<FontAwesomeIcon icon="fa-solid fa-angle-left" />} onClick={() => {navigate('/charts')}}>BACK TO CHARTS</Button>
            </Box>
            <Flex flexDirection={{base: 'column', md: 'row'}} width={'100%'} alignItems={'flex-start'} justifyContent={'space-between'}>
              <VStack alignItems={'flex-start'}>
                <Heading size={'xl'} lineHeight={'1'}>{activeMarket?.json?.Title}</Heading>
                <Breadcrumbs crumbs={breadcrumbs} />
              </VStack>
              <VStack spacing={0} alignItems={'flex-end'} mt={{base: 10, md: 0}}>
                <HStack>
                  <Fullscreen
                    open={<Button leftIcon={<FontAwesomeIcon icon="fa-sharp fa-solid fa-maximize" size="lg" />} sx={buttonStyles}>Fullscreen</Button>}
                  >
                    <Chart maxChartHeight={chartHeightFS} />
                  </Fullscreen>
                  <DownloadBlobButton blob={chartBlob} filename={filename} />
                </HStack>
              </VStack>
            </Flex>
          </Box>
          <ChartFooter />
          <Box
          className='single-view'
            ref={chartRef}
            sx={{
              position: 'relative',
              width: '100%',
              backgroundColor: 'chart',
              border: '2px solid',
              borderColor: 'bgSecondary',
              borderRadius: 'sm',
              overflow: 'hidden',
            }}
          >
            <Chart maxChartHeight={maxChartHeight} />
          </Box>
        </Flex>
      }
    </>
  );
}