//Dependencies
import { 
  useState, 
  useEffect, 
  useContext, 
  useMemo,
  useRef,
  createContext 
} from 'react';
import { useSearchParams, useParams } from 'react-router-dom';

//Providers
import { useUser } from '../providers/user';
import { useMarkets } from '../providers/markets';
import { useWaveFinder } from '../providers/wave-finder';

//Helpers
import { load } from '../helpers/bchart';
import { createMarketURLBinaryTokenized } from '../helpers/utils';
import { getMarketBySymbol } from '../helpers/utils';


const ChartContext = createContext();

function useChart() {
  const context = useContext(ChartContext);
  if (!context) {
    throw new Error(`useChart must be used within a ChartProvider`);
  }

  return context;
}

function ChartProvider(props) {
  const {user} = useUser();
  const {markets} = useMarkets();
  const {waves, wfActive, isLoadingWaves} = useWaveFinder();

  const {json} = props;
  const {market: marketParam} = useParams();
  const isSingleView = useMemo(() => marketParam ? true : false, [marketParam]); // Used specifically for the single view that receives a market query param

  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamZoom = useMemo(() => parseInt(searchParams.get('z')), [searchParams]); // If z doesn't exist then NaN is returned
  const [zoom, setZoom] = useState(null); // Used to sync zoom between chart components (NOTE: Not persistent navigating from Single -> Charts - Requires refractor)

  const [chartBlob, setChartBlob] = useState(null); // Used to sync zoom between chart components (NOTE: Not persistent navigating from Single -> Charts - Requires refractor)
  const [bchart, setBchart] = useState(null); //Necessary for creating bchart which holds default data for market

  const [activeMarket, setActiveMarket] = useState(null);
  const [chartInView, setChartInView] = useState(false);

  // Create a single active Market object that includes json, waves, and bchart data
  useEffect(() => {
    const activeJson = !json ? getMarketBySymbol(markets, marketParam) : json;

    const active = {
      "json": activeJson,
      "binary": bchart,
      "waves": waves?.filter(wave => wave?.market === activeJson?.Id),
    }

    setActiveMarket(active);
  }, [json, markets, bchart, marketParam, waves]);

  // Loads the binary chart data object into chart provider when it's available (inView)
  useEffect(() => {
    const getBinaryChartAsync = async (marketToken, userPackage, cleanup) => {
      const path = createMarketURLBinaryTokenized(marketToken, userPackage);
      try {
        if(path) {
          const object = await load(path, cleanup);
          setBchart(object);
        }
      } catch (error) {
        console.error('Error loading binary path', error);
      }
    }

    const cleanup = new AbortController();

    if(chartInView && activeMarket?.json?.Token && user?.package) {
      getBinaryChartAsync(activeMarket.json.Token, user.package, cleanup);
    }

    return () => {
      cleanup.abort();
    }
  }, [activeMarket?.json?.Token, user?.package, chartInView]);
  
  // Setup what zoom should be
  useEffect(() => {
    if(isLoadingWaves) {
      setZoom(null);
    } else {

      // Ensure we have binary data and that matches the active
      if(activeMarket && bchart && activeMarket?.json?.Symbol === bchart?.symbol) {
        const numOfZooms = bchart.zooms.length;
        const paramZoom = parseInt(searchParamZoom);
  
        let zoomToLoad = null;
  
        if(wfActive) {
          // If wave finder is active, then we want to set the zoom to the first wave's zoom level
          zoomToLoad = activeMarket?.waves && activeMarket.waves[0]?.zoom ? activeMarket.waves[0].zoom : bchart?.defaultZoom;
        } else {
          // If wave finder is not active, then we want to set the zoom to the default zoom level
          zoomToLoad = bchart?.defaultZoom;
        }
 
        if(isSingleView) {
          if(!isNaN(paramZoom)) {
            // Ensure we have a valid zoom level before setting it when dealing w/ single views
            if(paramZoom >= numOfZooms || paramZoom < 0) {
              setZoom(zoomToLoad);
            } else {
              setZoom(paramZoom);
            }
          } else {
            setZoom(zoomToLoad);
          }
        } else {
          setZoom(zoomToLoad);
        }
      }
    }
  }, [wfActive, activeMarket, bchart, isSingleView, searchParamZoom, isLoadingWaves]);

  const value = useMemo(() => ({
    zoom, setZoom,
    chartBlob, setChartBlob,
    chartInView, setChartInView,
    activeMarket,
    bchart,
    isSingleView
  }), [zoom, chartBlob, chartInView, activeMarket, bchart, isSingleView]);

  return <ChartContext.Provider value={value} {...props} />
}

export {ChartProvider, useChart};