//Dependencies
import {
  useMemo,
  useEffect
} from 'react';
import isEqual from 'lodash.isequal';

//Chakra
import {
  Text,
  Flex,
} from '@chakra-ui/react';
import { ewavesConfig } from '../config/theme-config';

//Components
import {
  // ChartTags,
} from '../components';

//Providers
import { useWaveFinder } from '../providers/wave-finder';
import { useChart } from '../providers/chart';

//Helpers
import { toTitleCase } from '../helpers/utils'; 


export default function ChartFooter() {
  const {activeMarket, bchart} = useChart();
  const {sortBy, queryInfo} = useWaveFinder();

  const currentWave = useMemo(() => {
    let curWave;

    const waves = activeMarket?.waves;

    if(waves && waves.length) {
      curWave = waves[0];
    } else {
      curWave = bchart;
    }

    return curWave;
  }, [activeMarket, bchart]);

  const sortInfo = useMemo(() => {
    if(currentWave) {
      const value = parseInt(currentWave[sortBy]);
      let units = queryInfo?.attributes?.[sortBy]?.units;

      if(value === undefined || isNaN(value)) {
        return null
      };

      return (`${toTitleCase(sortBy)}: ${Math.round(value)} ${units ? units : ''}`);
    } else {
      return null;
    }
  }, [queryInfo, currentWave, sortBy ]);

  const elliotticitySortInfo = useMemo(() => {
      if(queryInfo && currentWave) {
        let units = queryInfo?.attributes['elliotticity'] ? queryInfo.attributes['elliotticity'].units : '';
        return (`${toTitleCase('Elliotticity')}: ${Math.round(currentWave?.elliotticity)} ${units ? units : ''}`);
      }
      return null;
  }, [queryInfo, currentWave]);
  
  return (
    <Flex className={'chart-footer'} rowGap={'3px'} lineHeight={1} flexDir={'column'} alignItems={'flex-end'} justifyContent={'center'} p={'xs'} width={'100%'} minH={ewavesConfig.chartFooterMinHeight+"px"}>
      {currentWave && //If we have waves, show the wave tags
        <>
          {sortInfo //If we have sort info, show it otherwise fallback to elliotticity info
            ?
            <Text fontSize={'sm'} fontWeight={'700'}>{sortInfo}</Text>
            :
            <Text fontSize={'sm'} fontWeight={'700'}>{elliotticitySortInfo}</Text>
          }
          {sortBy !== 'elliotticity' && elliotticitySortInfo && sortInfo &&//If we are not sorting by elliotticity, show elliotticity info as a subtext
            <Text fontSize={'xs'} fontWeight={'400'}>({elliotticitySortInfo})</Text>
          }
        </>
      }
    </Flex>
  )
}