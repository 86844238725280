// Dependencies
import { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isEqual from "lodash.isequal";

// Chakra
import {
  Box,
  Button,
  Text,
  Flex,
  Menu,
  MenuGroup,
  MenuButton,
  MenuList,
  MenuDivider,
  MenuItem,
  Heading,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Input,
  Tooltip,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";

// Helpers
import { toTitleCase } from "../../helpers/utils";

// Providers
import { useSetups } from "../../providers/setups";
import { useBuilder } from "../../providers/builder";
import { useUser } from "../../providers/user";

// Helpers
import { useDebounce } from "../../hooks";

const USER_SETUP_LIMIT = 10;
const USER_SETUP_CHAR_LIMIT = 30;
const NEW_SETUP_NAME = 'New Setup';

const truncateTextStyles = {
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  lineHeight: '1.6',
}

const SetupsSelector = ({handleNewSetup: handleNewSetupContexts}) => {
  const {user} = useUser();
  const {actions: bActions, didConvert} = useBuilder();
  const {setups, builtinSetups, userSetups, setUserSetups, currentSetup, setCurrentSetup, isModifiedSetup, setIsModifiedSetup, isNew, setIsNew} = useSetups();
  const [hasInput, setHasInput] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [renameValue, setRenameValue] = useState('');
  const [isDeletingSetup, setIsDeletingSetup] = useState(false);
  const [setupToDelete, setSetupToDelete] = useState(null);
  const [nameErrorMessage, setNameErrorMessage] = useState(null);
  const [canLoadSetup, setCanLoadSetup] = useState(false);
  const debouncedRenameValue = useDebounce(renameValue.trim(), 100);
  const {isOpen, onOpen, onClose} = useDisclosure();

  const hasUserSetups = useMemo(() => userSetups?.length, [userSetups]);
  const isBuiltinSetup = useMemo(() => currentSetup?.type === 'builtin', [currentSetup?.type]);

  const [selectedSetup, setSelectedSetup] = useState({name: null, type: null});

  const selectorRef = useRef(null);
  const cancelRef = useRef(null);
  const refreshRef = useRef(true);

  // Compare user setup names to prevent duplicates
  const validateNewUserSetup = useCallback((name) => {
    const userSetupNames = userSetups?.map(setup => setup.name.toLowerCase());
    return userSetupNames?.includes(name.toLowerCase());
  }, [userSetups]);

  // Make sure only letters, numbers, and spaces are allowed in setup names
  const validateInput = useCallback((input) => {
    const validate = new RegExp(/[^a-zA-Z0-9 ]/g, '');
    return validate.test(input);
  }, []);

  useEffect(() => {
    const isValidName = !validateInput(debouncedRenameValue);
    const isDuplicateName = validateNewUserSetup(debouncedRenameValue) || debouncedRenameValue === "New Setup";
    const isWithinLimit = debouncedRenameValue.length <= USER_SETUP_CHAR_LIMIT;

    if(isDuplicateName) {
      setNameErrorMessage('Name already exists or is invalid.');
    } else if(!isValidName) {
      setNameErrorMessage('Name contains invalid characters. Only letters, numbers, and spaces are valid.');
    } else if(!isWithinLimit) {
      setNameErrorMessage('Name exceeds the character limit of 50.');
    } else {
      setNameErrorMessage(null);
    }
  }, [debouncedRenameValue, validateNewUserSetup, validateInput]);

  const loadSetupByName = useCallback((name) => {
    const copySetups = structuredClone(setups);
    const nextSelectedSetup = copySetups?.find(setup => setup.name === name); // Find setup by name in the setups array

    if(!refreshRef.current && nextSelectedSetup) {
      const thisBuiltin = structuredClone(builtinSetups).find(setup => setup.name === name); //Determine if we're reloading a builtin setup

      const replacementSetup = thisBuiltin ? thisBuiltin : nextSelectedSetup;

      console.log('Replace with...', replacementSetup);
      bActions.replaceBuilderState({name: replacementSetup.name, setup: replacementSetup.setup});

      // Make sure all our various states are reset if loading new setup
      setIsModifiedSetup(false);
      setIsNew(false);
      setIsRenaming(false);
      setIsDeletingSetup(false);
      setCanLoadSetup(false);
      setHasInput(false);
    }
  }, [setups, builtinSetups, bActions, setIsModifiedSetup, setIsNew]);

  // Load a new blank setup
  const handleNewSetup = useCallback(() => {
    const newSetup = {
      name: NEW_SETUP_NAME,
      setup: [],
      type: 'user'
    }

    handleNewSetupContexts();
    setIsNew(true);
    setCurrentSetup(newSetup);
    bActions.replaceBuilderState({name: newSetup.name, setup: newSetup.setup});
    setIsModifiedSetup(false);
  }, [bActions, handleNewSetupContexts, setCurrentSetup, setIsModifiedSetup, setIsNew]);

  // Prepare for showing proper alert message to rename
  const handleRenameAlert = () => {
    setHasInput(true);
    setIsRenaming(true);
    onOpen();
  }

  // Save the new name to the current setup
  const handleRenameSetup = useCallback(() => {
    const copyUserSetups = user.savedUserSetups ? structuredClone(user.savedUserSetups) : [];
    const thisUserSetupIndex = copyUserSetups.findIndex(setup => setup.name === currentSetup.name);

    if(thisUserSetupIndex !== -1) {
      const copyCurrentSetup = structuredClone(currentSetup);
      copyCurrentSetup.name = debouncedRenameValue;

      copyUserSetups.splice(thisUserSetupIndex, 1, copyCurrentSetup);

      // Update user setups with new setup
      setUserSetups(copyUserSetups);

      bActions.replaceBuilderState({name: copyCurrentSetup.name, setup: copyCurrentSetup.setup});
      setCurrentSetup(copyCurrentSetup);
  
      // Reset flags for UI States
      setIsModifiedSetup(false);
      onClose();
      setRenameValue('');
      setHasInput(false);
      setIsRenaming(false);
      setCanLoadSetup(true);
    }
  }, [bActions, currentSetup, debouncedRenameValue, onClose, setCurrentSetup, setIsModifiedSetup, setUserSetups, user.savedUserSetups]);

  // Loads a setup (built-in or user) when user selects from list of options and then replaces builder state
  const handleSetupSelection = (name, type, menuState) => {
    if(!isModifiedSetup) {
      loadSetupByName(name);
      setIsModifiedSetup(false);
    } else {
      setSelectedSetup({name, type});
      onOpen();
    }

    menuState.onClose();
  }

  // Save changes to current setup
  const handleSaveSetup = useCallback((setup) => {
    const copyUserSetups = structuredClone(userSetups);
    const newUserSetups = copyUserSetups.map(cSetup => {
      if(cSetup.name === setup.name) {
        return setup;
      }
      return cSetup;
    });
    setUserSetups(newUserSetups);
    setIsModifiedSetup(false);
  }, [userSetups, setUserSetups, setIsModifiedSetup]);

  // Continues loading a setup without saving any values
  const handleContWithoutSave = ({name}) => {
    loadSetupByName(name);
    setIsModifiedSetup(false);
    onClose();
  }

  // Closes alert dialog and does anything else extra needed here
  const handleAlertClose = () => {
    setRenameValue('');
    setHasInput(false);
    setIsDeletingSetup(false);
    onClose();
  }

  // Open alert and switch to renaming states
  const handleAlertSetupSaveAs = () => {
    setHasInput(true);
    onOpen();
  }

  // Save value from rename input field when changed, is debounced by for performance improvements
  const handleRenameChange = useCallback((event) => {
    function sanitizeString(input) {
      return input
        .replace(/[^a-zA-Z0-9 ]/g, '')   // Step 1: Remove unwanted characters
        .replace(/\s+/g, ' ')            // Step 2: Replace multiple spaces with one
    }

    const sanitizedValue = sanitizeString(event.target.value);
    setRenameValue(sanitizedValue);
  }, []);

 
  // Handle duplicating a current setup and saving as a new user setup
  const handleSetupSaveAs = useCallback(async () => {
    if(debouncedRenameValue) {
      const currUserSetups = user?.savedUserSetups ? user.savedUserSetups : [];
      const newUserSetups = [...currUserSetups, {name: debouncedRenameValue, setup: user?.querySetup?.setup}];

      if(isNew) {
        setIsNew(false);
      }

      // Update user setups with new setup
      setUserSetups(newUserSetups);
      
      // Reset flags for UI States
      setIsModifiedSetup(false);
      onClose();
      setRenameValue('');
      setHasInput(false);
      setCanLoadSetup(true);
    }
  }, [debouncedRenameValue, user?.savedUserSetups, user?.querySetup?.setup, isNew, setUserSetups, setIsModifiedSetup, onClose, setIsNew]);

  // Handle removing a user setup from the "savedUserSetups" array that is stored in Firestore
  const handleRemoveUserSetup = useCallback(async (name) => {
    console.log('Removing...', name);
    const removeSetupIndex = setups.findIndex(setup => setup.name === name); // index of setup we're removing
    const currSetupIndex = setups.findIndex(setup => setup.name === currentSetup.name);

    // Trim user setups of the setup we're deleting and update
    const trimmedUserSetups = structuredClone(user?.savedUserSetups).filter(setup => setup.name.toLowerCase() !== name.toLowerCase());
    setUserSetups(trimmedUserSetups);
    setIsDeletingSetup(false);
    setSelectedSetup({name: null, type: null});
    onClose();

    // If we're removing the setup that's currently active we'll need to load one to replace it
    if(removeSetupIndex === currSetupIndex) { 
      const nextSetup = setups.find((setup, index) => index === removeSetupIndex + 1);
      console.log(nextSetup);
      loadSetupByName(nextSetup.name);
    }
  }, [setups, currentSetup?.name, user, setUserSetups, loadSetupByName, onClose]);

  // Handle removing a setup alert prompt
  const handleRemoveSetupAlert = (name) => {
    onOpen();
    setIsDeletingSetup(true);
    setSetupToDelete(name);
    setSelectedSetup({name, type: 'user'});
  }

  const currAlertMessage = useMemo(() => {
    let message;

    const thisIsBuiltinSetup = selectedSetup?.type === 'builtin' ? true : false;

    if(thisIsBuiltinSetup && !hasInput) {
      message = (<Text>You have unsaved changes to a built-in setup template. <br/> What would you like to do?</Text>);
    }
      
    if(!thisIsBuiltinSetup && !hasInput && !isDeletingSetup) {
      message = (<Text>You have unsaved changes to a user setup template. <br/> What would you like to do?</Text>);
    }

    if(!thisIsBuiltinSetup && !hasInput && isDeletingSetup) {
      message = (<Text>If you delete this setup it will be permanently removed. <br/> What would you like to do?</Text>);
    }
      
    if(hasInput) {
      message = 
      (<>
        <Text>Please provide a name for this setup:</Text>
        <Input value={renameValue} onChange={handleRenameChange} placeholder='Name of setup...' size='md' mt={'xs'} />
        <Text fontSize={'xs'} color={'gray.500'}>Setup names must be unique and contain only letters, numbers, and single spaces.</Text>
      </>)
    }

    return message;
  }, [selectedSetup, hasInput, renameValue, isDeletingSetup, handleRenameChange]);

  const currAlertTitle = useMemo(() => {
    let message;

    if(!hasInput) {
      if(isDeletingSetup) {
        message = <Text>You're deleting a setup.</Text>
      } else {
        message = <Text>Are you sure you want to switch setups?</Text>;
      }
    } else {
      if(isNew) {
        message = <Text>You're saving a new setup.</Text>
      } else if(isRenaming) {
        message = <Text>You're renaming the current setup.</Text>
      } else {  
        message = <Text>You're saving a copy of the active setup.</Text>
      }
    }
    
    return message;
  }, [hasInput, isNew, isRenaming, isDeletingSetup]);

  // Monitors templates and adds a notification icon if it's modified from original loaded state. IE - determines 'isModifiedSetup' on render
  useEffect(() => {
    if(user?.querySetup && setups.length) {
      const savedSetup = setups.find((setup) => setup?.name === user?.querySetup?.name);
      
      // Make sure we're comparing the current setup to the saved setup being loaded into the UI
      if(savedSetup && (currentSetup?.name === user.querySetup.name)) {
        const {name, setup} = savedSetup;

        // console.log('Checking for modified setup...', user?.querySetup, {name, setup});
        if(!isEqual(user?.querySetup, {name, setup})) {
          // console.log('Modified setup detected...');
          setIsModifiedSetup(true);
        } else {
          // console.log('Modified setup NOT detected...');
          setIsModifiedSetup(false);
        }
      }
    }
  }, [setups, currentSetup, user?.querySetup, setIsModifiedSetup]);

  // Load the active setup by name when changed
  useEffect(() => {
    if(canLoadSetup && debouncedRenameValue !== '') {
      loadSetupByName(debouncedRenameValue);
    }
  }, [debouncedRenameValue, canLoadSetup, loadSetupByName]);

  // Check if the current setup is a new setup that hasn't been saved to database. IE - determines 'isNew' on render
  useEffect(() => {
    if(builtinSetups.length) {
      const copySavedUserSetups = structuredClone(user?.savedUserSetups);
      const isSavedSetup = copySavedUserSetups?.some(setup => setup.name === currentSetup?.name);
      const isBuiltin = currentSetup?.type === 'builtin' ? true : false;

      if(!isSavedSetup && !isBuiltin && !didConvert) {
        // console.log('New setup detected...');
        setIsNew(true);
        setIsModifiedSetup(true);
      } else {
        setIsNew(false);
      }

      refreshRef.current = false;
    }
  }, [builtinSetups, currentSetup, didConvert, setIsModifiedSetup, setIsNew, user?.savedUserSetups]);

  return (
    <>
    {setups &&
        <Flex direction={'column'} gap={'xs'} className={'setups-wrapper'} position={'relative'} mb={'xs'} w={'100%'}>
          <Flex direction={'row'} justifyContent={'space-between'} mb={'1'}>
            <Heading display={'flex'} gap={'1'} alignItems={'center'} size={'xs'} color={'gray.600'}>Setups</Heading>
            <Flex className="setups-toolbar" gap={'2px'} justifyContent={'end'}>
              <Tooltip label={userSetups?.length >= USER_SETUP_LIMIT ? 'Setup Limit Reached' : 'New setup'}>
                <IconButton
                  className="setup-new"
                  colorScheme={'gray'}
                  variant={'ghost'}
                  size={'sm'}
                  icon={<FontAwesomeIcon size={'lg'} icon="fa-solid fa-plus" />}
                  onClick={() => {
                    handleNewSetup();
                    setIsModifiedSetup(true);
                  }}
                  isDisabled={userSetups?.length >= USER_SETUP_LIMIT ? true : false}
                />
              </Tooltip>
              <Tooltip label={'Save setup'}>
                <IconButton
                  className="setup-save"
                  isDisabled={(isBuiltinSetup || isNew || !isModifiedSetup) ? true : false}
                  colorScheme={'gray'}
                  variant={'ghost'}
                  size={'sm'}
                  icon={
                    <>
                    {isModifiedSetup && !isBuiltinSetup && !isNew
                      ? <FontAwesomeIcon size={'lg'} icon="fa-kit fa-solid-floppy-disk-circle-exclamation" />
                      : <FontAwesomeIcon size={'lg'} icon="fa-solid fa-floppy-disk" />}
                    </>
                  }
                  onClick={() => handleSaveSetup(user?.querySetup)}
                />
              </Tooltip>
              <Tooltip label={userSetups?.length >= USER_SETUP_LIMIT ? 'Setup Limit Reached' : 'Save setup as...'}>
                <IconButton
                  className="setup-save-as"
                  colorScheme={'gray'}
                  variant={'ghost'}
                  size={'sm'}
                  isDisabled={userSetups?.length >= USER_SETUP_LIMIT ? true : false}
                  icon={
                    <>
                    {(isModifiedSetup && !(userSetups?.length >= USER_SETUP_LIMIT)) || isNew
                      ? <FontAwesomeIcon size={'lg'} icon="fa-kit fa-solid-floppy-disks-circle-exclamation" /> 
                      : <FontAwesomeIcon size={'lg'} icon="fa-solid fa-floppy-disks" />}
                    </>
                  }
                  onClick={handleAlertSetupSaveAs}
                />
              </Tooltip>
              <Tooltip label={'Rename setup'}>
                <IconButton 
                  className="setup-rename"
                  isDisabled={isBuiltinSetup || isNew ? true : false}
                  colorScheme={'gray'} 
                  variant={'ghost'} 
                  size={'sm'} 
                  icon={<FontAwesomeIcon size={'lg'} icon="fa-kit fa-solid-floppy-disk-pen" />}
                  onClick={() => handleRenameAlert()}
                />
              </Tooltip>
            </Flex>
          </Flex>
          <Menu matchWidth closeOnSelect={false} autoSelect={false}>
          {(menuState) => (
            <>
              <MenuButton
                width={'100%'}
                paddingInline={'xs'} 
                paddingBlock={'xs'} 
                lineHeight={'1'} 
                color={currentSetup?.name ? 'white' : 'gray.500'}
                backgroundColor={currentSetup?.name ? 'green.500' : 'gray.100'}
                _active={{backgroundColor: currentSetup?.name ? 'green.600' : 'gray.300'}}
                _hover={{backgroundColor: currentSetup?.name ? 'green.600' : 'gray.300'}}
                borderWidth={'2px'} 
                borderColor={currentSetup?.name ? 'green.500' : 'gray.300'} 
                fontSize={'sm'}
                textAlign={'left'}
                as={Button}
                rightIcon={<FontAwesomeIcon size='sm' icon='fa-solid fa-chevron-down' />}
                ref={selectorRef}
              >
                <Flex alignItems={'center'} gap={'xs'}>
                  <Text sx={truncateTextStyles}>{currentSetup?.name ? currentSetup.name : 'Please Select...'}{currentSetup?.name !== null && isModifiedSetup && ' *'}</Text>
                </Flex>
              </MenuButton>
              <MenuList>
                <MenuGroup
                  as={'div'}
                  title={
                    <Flex direction={'row'} alignItems={'baseline'}>
                      <Box>User Setups</Box>
                      <Box ml={'4px'} fontSize={'xs'} color={'green.400'}>({userSetups?.length} of {USER_SETUP_LIMIT})</Box>
                    </Flex>
                  }
                  m={0} marginBlockEnd={'xs'}
                  p={0} pl={'xs'}
                >
                  {!hasUserSetups &&
                    <MenuItem m={0} p={0} pl={'sm'} key={0} value={false} fontSize={'sm'} isDisabled>No User Setups Found.</MenuItem>
                  }
                  
                  {setups.filter(setup => setup.type === 'user').map((setup, index) => (
                    <MenuItem
                      key={index+setup?.name} 
                      justifyContent={'space-between'} m='0' p='0' fontSize={'sm'}
                      _hover={{backgroundColor: 'gray.100'}}
                    >
                      <Box paddingInline={'xs'} paddingBlock={'0.5'} pl={'sm'} flexGrow={1} onClick={() => handleSetupSelection(setup?.name, 'user', menuState)} sx={truncateTextStyles}>{toTitleCase(setup?.name)}</Box>
                      <Box 
                        paddingInline={'xs'}
                        paddingBlock={'0.5'}
                        direction={'row'} 
                        gap={'xs'} 
                        color={'gray.300'} 
                        _hover={{backgroundColor: 'red.100', color: 'red.500'}}
                        onClick={() => handleRemoveSetupAlert(setup?.name)}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-times" />
                      </Box>
                    </MenuItem>
                  ))}
                </MenuGroup>
                <MenuDivider />
                <MenuGroup title='Built-in Setups' m={0} marginBlockEnd={'xs'} p={0} pl={'xs'}>
                  {setups.filter(setup => setup.type === 'builtin').map((setup, index) => (
                    <MenuItem
                      key={index+setup?.name} 
                      justifyContent={'space-between'} m='0' p='0' fontSize={'sm'}
                      _hover={{backgroundColor: 'gray.100'}}
                    >
                      <Box paddingInline={'xs'} paddingBlock={'0.5'} pl={'sm'} flexGrow={1} onClick={() => handleSetupSelection(setup?.name, 'builtin', menuState)} sx={truncateTextStyles}>{toTitleCase(setup?.name)}</Box>
                    </MenuItem>
                  ))}
                </MenuGroup>
              </MenuList>
            </>
          )}
          </Menu>
        </Flex>
    }
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      finalFocusRef={selectorRef}
      onClose={handleAlertClose}
      size={'xl'}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            {currAlertTitle}
          </AlertDialogHeader>

          <AlertDialogBody bgColor={'transparent'}>
            {currAlertMessage}
            {nameErrorMessage &&
              <Alert status="error" mt='xs' variant='subtle' p={'xs'} fontSize={'sm'}>
                <AlertIcon />
                <AlertTitle fontSize={'xs'}>{nameErrorMessage}</AlertTitle>
              </Alert>
            }
          </AlertDialogBody>

          <AlertDialogFooter>
            {!hasInput 
              ?
              <>
                {isDeletingSetup
                  ? <>
                      <Button onClick={() => handleAlertClose()} ml={3}>Go Back</Button>
                      <Button colorScheme='red' onClick={() => handleRemoveUserSetup(setupToDelete)} ml={3}>Delete</Button>
                    </>
                  : 
                    <>
                      <Button ref={cancelRef} onClick={() => handleContWithoutSave(selectedSetup)}>Continue Without Saving</Button>
                      <Button colorScheme='green' onClick={() => handleAlertClose()} ml={3}>Go Back</Button>
                    </>
                }
              </>
              :
              <>
                <Button onClick={() => handleAlertClose()} ml={3}>Cancel</Button>
                {isRenaming 
                ?
                  
                  <Button colorScheme='green' isDisabled={(debouncedRenameValue === '' || nameErrorMessage) ? true : false} onClick={() => handleRenameSetup()} ml={3}>Rename</Button>
                :
                  <Button colorScheme='green' isDisabled={(debouncedRenameValue === '' || nameErrorMessage) ? true : false} onClick={() => handleSetupSaveAs()} ml={3}>Save As</Button>
                }
              </>
            }
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
    </>
  )
}

export default SetupsSelector;