//Dependencies
import {
  cloneElement,
  useEffect,
  useState
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Chakra
import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from '@chakra-ui/react';

//Components
import { TargetsToggle } from '../components';

//Providers
import { useView } from '../providers/view';
import { useWaveFinder } from '../providers/wave-finder';


export default function Fullscreen({children, ...props}) {
  const {setFullscreen, targetsAvailable} = useView();
  const {wfActive} = useWaveFinder();
  const {isOpen, onOpen, onClose} = useDisclosure();

  const handleClick = () => {
    onOpen();
    setFullscreen(true);
  }

  const handleClose = () => {
    onClose();
    setFullscreen(false);
  }

  const styles = {
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: 0.5,
    zIndex: 'modal',
    '&:hover, &:focus': {
      opacity: 1,
      bgColor: 'green.300'
    }
  }

  // Modal content-container has width 100vw, that messes up Firefox!
  return (
    <>
      {cloneElement(props.open, {onClick: handleClick, className: 'fullscreen', title:"Maximize Chart"})}
      <Modal isOpen={isOpen} onClose={handleClose} isCentered={true} scrollBehavior={'outside'} size={'full'} allowPinchZoom={true}>
        <ModalOverlay />
        <ModalContent height={'100svh'}>
          <Button onClick={handleClose} sx={styles} size={'md'}><FontAwesomeIcon icon="fa-solid fa-times" size="2xl" /></Button>
          {wfActive && targetsAvailable && <TargetsToggle sx={{position: 'absolute', padding: 'xs', top: 0, left: '65%', transform: 'translateX(-50%)', opacity: 0.5, borderRadius:'sm',  zIndex: 'modal', '&:hover, &:focus': {opacity: 1, bgColor: 'gray.200'}}} />}
          <ModalBody p={0} display={'flex'} alignItems={'center'}>
            {children}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
