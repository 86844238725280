// Dependencies
import { useEffect, useState, useRef, useContext, useMemo, createContext } from 'react';
import { onSnapshot, doc } from "firebase/firestore";
import isEqual from 'lodash.isequal';

// Providers
import { convertQueryToBuilderState, useBuilder } from '../builder';

//Helpers
import { db, setFBUser } from '../../helpers/firebase';
import { useAuthentication } from '../authentication';
import { useUser } from '../user';


const SetupsContext = createContext();

function SetupsProvider(props) {
  const {authentication} = useAuthentication();
  const {user, serverUpdateType} = useUser();
  const {didConvert, setDidConvert} = useBuilder();
  const [userSetups, setUserSetups] = useState(user?.savedUserSetups ? user?.savedUserSetups : []);
  const [builtinSetups, setBuiltinSetups] = useState([]);
  const [currentSetup, setCurrentSetup] = useState(user?.querySetup);
  const [isModifiedSetup, setIsModifiedSetup] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [setups, setSetups] = useState([]);

  const prevUserSetups = useRef(userSetups);

  // This is to keep the userSetups in sync with the user data if an old query user converts to querySetup
  useEffect(() => {
    if(didConvert && user?.savedUserSetups) {
      setUserSetups(user.savedUserSetups);
      setDidConvert(false);
    }
  }, [didConvert, setDidConvert, user.savedUserSetups]);

  // Re-initialize userSetups when user updates
  useEffect(() => {
    if(user?.savedUserSetups && serverUpdateType === 'Server') {
      setUserSetups(user.savedUserSetups);
      prevUserSetups.current = user.savedUserSetups;
    }
  }, [user?.savedUserSetups, serverUpdateType]);

  // Set the setups array once both userSetups and builtinSetups are populated
  useEffect(() => {
    if(userSetups?.length || builtinSetups?.length) {
      const copyUserSetups = structuredClone(userSetups).map(setup => ({...setup, type: 'user'})).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      const copyBuiltinSetups = structuredClone(builtinSetups).map(setup => ({...setup, type: 'builtin'})).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

      // Update setups once both userSetups and builtinSetups are populated
      setSetups([...copyUserSetups, ...copyBuiltinSetups]);
    }
  }, [userSetups, builtinSetups, user]);

  // This keeps Firebase sync'd with the UI
  useEffect(() => {
    async function updateFBUserSetups() {
      try {
        setFBUser(authentication?.uid, { savedUserSetups: userSetups });
      } catch (e) {
        console.error(e);
      }
    }

    if(!isEqual(prevUserSetups.current, userSetups)) {
      console.log('User setups saving As...');
      updateFBUserSetups().then(() => {
        console.log('Updated to Firebase!');
        prevUserSetups.current = userSetups;
      });
    }
  }, [userSetups, authentication?.uid, user]);

  // We grab these from Firebase because they aren't kept in the user data
  useEffect(() => {
    const unsubscribeBuiltinSetups = onSnapshot(doc(db, "site", "config"), (snapshot) => {
      const fbTemplates = JSON.parse(snapshot.data()?.templates || "[]");
      const builtins = fbTemplates.map(template => convertQueryToBuilderState(template));
      setBuiltinSetups(builtins);
    });

    return () => {
      unsubscribeBuiltinSetups();
    };
  }, [authentication]);

  // Set the current setup when the user's querySetup changes and determine what type of setup it is
  useEffect(() => {
    if(builtinSetups.length && user?.querySetup !== null) {
      const isBuiltin = builtinSetups.some(setup => setup?.name === user?.querySetup?.name);

      const currSetup = {
        ...user?.querySetup,
        type: isBuiltin ? 'builtin' : 'user'
      };

      setCurrentSetup(currSetup);
    }
  }, [user?.querySetup, builtinSetups]);

  const value = useMemo(() => ({
    setups,
    userSetups, setUserSetups,
    builtinSetups,
    currentSetup, setCurrentSetup,
    isModifiedSetup, setIsModifiedSetup,
    isNew, setIsNew
  }), [setups, userSetups, builtinSetups, currentSetup, isModifiedSetup, isNew]);

  return <SetupsContext.Provider value={value} {...props} />;
}

function useSetups() {
  const context = useContext(SetupsContext);

  if (!context) {
    throw new Error(`useQuery must be used within a SetupsProvider`);
  }

  return context;
}

export {SetupsProvider, useSetups};