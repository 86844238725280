//Dependencies
import {
  useState,
  useEffect,
} from 'react';

//Helpers
import { to_svg } from '../helpers/bchart';

//Providers
import { useChart } from '../providers/chart';


export default function useBinaryChart() {
  const {
    activeMarket,
    zoom,
    bchart,
    chartInView,
  } = useChart();

  const [ chartHTML, setChartHTML ] = useState(null);

  useEffect(() => {
    if(chartInView && zoom !== null) {
      if(bchart && (activeMarket?.json?.Symbol !== bchart?.symbol)) { //Make sure market and bchart are the same! 
        console.debug(`Market data mismatch! Bchart was expecting ${bchart?.symbol}, but found ${activeMarket?.json?.Symbol}.`);
        return;
      }

      console.debug('DATA', activeMarket, bchart, zoom);
  
      if(bchart && (zoom === 0 || zoom) && (zoom <= (bchart?.zooms?.length - 1))) {
        setChartHTML(to_svg(bchart, zoom));
      } else {
        console.debug(`Zoom was out of bounds. Retrying...`);
        return;
      }
    }
  }, [bchart, zoom, activeMarket?.json?.Symbol, chartInView, activeMarket]);

  return {
    bchart,
    chartHTML
  };
}