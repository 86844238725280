//Dependencies
import { useState, useEffect, useContext, useMemo, useCallback, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut, getIdToken } from 'firebase/auth';
// import isEqual from 'lodash.isequal';

//Helpers
import { auth } from '../helpers/firebase';
import { getStorage } from '../helpers/storage';


const AuthenticationContext = createContext();

function useAuthentication() {
  const context = useContext(AuthenticationContext);

  if (!context) {
    throw new Error(`useAuthentication must be used within a AuthenticationProvider`);
  }
  return context
}

function AuthenticationProvider(props) {
  const navigate = useNavigate();
  const lastAuthSession = getStorage('auth');
  const [authentication, setAuthentication] = useState(lastAuthSession ? lastAuthSession : false);
  const [isLoading, setIsLoading] = useState(true);

  const logout = useCallback(() => {
    signOut(auth);
    navigate('/login', {replace: true});
  }, [navigate]);

  const value = useMemo(() => ({authentication, setAuthentication, isLoading, setIsLoading, logout}), [authentication, isLoading, logout]);

  useEffect(() => {
    //Monitor auth state changes and update route and currentUser accordingly
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.debug('Auth state changed...');

      //Get current location and then redirect back to that if logged in.
      const currURL = new URL(window.location.href);

      //Get search params and pass along to ensure auth check doesn't strip them when navigating directly to a chart
      const currURLSParams = new URLSearchParams(window.location.search);
      const currParams = currURLSParams.toString();

      if(user) {
        //User is logged in
        const authState = {
          verified: user.emailVerified,
          email: user.email,
          uid: user.uid,
          token: user.accessToken,
        }
        
        getIdToken(user)
          .then(token => { //Make sure we can get the Id token for current user. (If user hasn't logged out for awhile then their authorization could have changed. This will refresh it.)
            if(token && authState.verified) {
              console.debug('Saving auth state and redirecting...');
              setAuthentication(authState);

              navigate({
                pathname: currURL.pathname,
                search: '?'+currParams,
              }, {replace: true});
            }
          })
          .catch(e => { //An error getting the token could mean they've been logged out or the auth server is running.
            console.error('Auth Token Error: ' + e.message);
            setAuthentication(false);
            navigate('/login', {replace: true});
          });
      } else {
        //User is logged out
        console.debug('Clearing auth state and redirecting to login...');
        if(currURL.pathname !== "/account-setup" && currURL.pathname !== "/reset") {
          console.log('Redirecting to login...');
          navigate("/login", {replace: true});
        }
        setAuthentication(false);
      }
    });

    //Make sure to unsubscribe from our Authentication listener
    return () => unsubscribe();
  }, []);

  return <AuthenticationContext.Provider value={value} {...props} />
}

export {AuthenticationProvider, useAuthentication}